import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import branchRoute from '../app/controller/branch/router'
import productsRoute from '../app/controller/products/router'
import expenseRoute from '../app/controller/expense/router'
import purchaseRoute from '../app/controller/purchase/router'
import customerRoute from '../app/controller/customer/router'
import categoryRoute from '../app/controller/category/router'
import employeeRoute from '../app/controller/employee/router'
import salesTypeRoute from '../app/controller/sales-type/router'
import salesPriceRoute from '../app/controller/sales-price/router'
import paymentRoute from '../app/controller/payment/router'
import stockRoute from '../app/controller/stock/router'
import uomRoute from '../app/controller/uom/router'
import vendorRoute from '../app/controller/vendor/router'
import attendanceRoute from '../app/controller/attendance/router'
import productPartRoute from '../app/controller/product-part/router'
import inventoriInRoute from '../app/controller/inventory-in/router'
import inventoriOutRoute from '../app/controller/inventory-out/router'
import inventoriMoveRoute from '../app/controller/inventory-move/router'
import salesRoute from '../app/controller/sales/router'
import userManagementRoute from '../app/controller/user-management/router'
import { login as loginRoute } from '../app/controller/auth/router'
import DashboardRoute from '../app/controller/dashboard/router'
import ProfitRouter from '../app/controller/profit/router'
import posRouter from '../app/controller/pos/router'
import userRouter from '../app/controller/user/router'
import jobtitleRouter from '../app/controller/jobtitle/router'
import user_roleRouter from '../app/controller/user_role/router'
import menu_accessRouter from '../app/controller/menu/router'
import store from '../store/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      auth: true
    },
    redirect: '/dashboard'
  },
  branchRoute,
  user_roleRouter,
  posRouter,
  userRouter,
  productsRoute,
  expenseRoute,
  purchaseRoute,
  customerRoute,
  employeeRoute,
  salesTypeRoute,
  paymentRoute,
  categoryRoute,
  stockRoute,
  productPartRoute,
  uomRoute,
  vendorRoute,
  loginRoute,
  inventoriInRoute,
  inventoriOutRoute,
  inventoriMoveRoute,
  salesRoute,
  userManagementRoute,
  attendanceRoute,
  DashboardRoute,
  ProfitRouter,
  jobtitleRouter,
  salesPriceRoute,
  menu_accessRouter,

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path != '/login') {
    store.dispatch('setBeforeUrl', to.path)
  }
  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters['auth/user']) {
      next()
    } else {
      next('/login')
    }
  } else if (to.name === 'login') {
    if (store.getters['auth/user'] == null) {
      next()
    } else {
      if (store.getters['beforeUrl'] || to.name !== 'login') {
        next(store.getters['beforeUrl'])
      } else {
        next('/dashboard')
      }
    }
  } else {
    next()
  }
})

export default router
