export default {
  path: '/sales-type',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: '',
      name: 'sales-type',
      component: () => import('./index.vue')
    }
  ]
}
