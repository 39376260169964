export default {
  path: '/attendance',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: 'report',
      name: 'Attendance Report',
      component: () => import('./report.vue')
    }
  ]
}
