import Vue from 'vue'
import Vuex from 'vuex'
import layout from './layouts.js'
import auth from './auth'
import data from './data'
import snakbar from './snakbar'
import cart from './cart'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sidebar: true,
    beforeUrl: '',
    data_edit:{}
  },
  mutations: {
    setSidebar: (state, status) => {
      state.sidebar = status
    },
    setBeforeUrl: (state, url) => {
      state.beforeUrl = url
    },
    setDataEdit: (state, payload) => {
      state.data_edit = payload
    }
  },
  actions: {
    setSidebar: ({ commit }, status) => {
      commit('setSidebar', status)
    },
    setBeforeUrl: ({ commit }, url) => {
      commit('setBeforeUrl', url)
    },
    setDataEdit: ({ commit }, payload) => {
      commit('setDataEdit', payload)
    }
  },
  getters: {
    sidebar: state => state.sidebar,
    beforeUrl: state => state.beforeUrl,
    data_edit: state => state.data_edit
  },
  modules: {
    layout,
    auth,
    data,
    snakbar,
    cart
  }
})
