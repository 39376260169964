export default {
  path: '/inventory-out',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: '',
      name: 'Inventory Out',
      component: () => import('./index.vue')
    }
    , {
      path: 'report',
      name: 'Inventory Out Report',
      component: () => import('./report.vue')
    }
  ]
}
