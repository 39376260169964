export default {
  path: '/expense',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: '',
      name: 'Expense',
      component: () => import('./index.vue')
    },
    {
      path: 'report',
      name: 'Expense Report',
      component: () => import('./report.vue')
    }
  ]
}
