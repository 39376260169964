export default {
  path: '/stock',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: '',
      name: 'stock',
      component: () => import('./index.vue')
    },
    {
      path: 'report',
      name: 'Stock report',
      component: () => import('./report.vue')
    },
    {
      path: 'ballance',
      name: 'Stock Balance',
      component: () => import('./bellance.vue')
    }

  ]
}
