
export default {
  path: '/dashboard',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: '',
      name:'Dashboard',
      component: () => import('./index.vue'),
      meta: { auth: true },
    }
  ]
}
