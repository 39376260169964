<template>
<div>

<v-navigation-drawer app v-model="drawer" class="light" width="300px" :color="color">
	  <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title style="white-space: normal;">{{user.fullname}}</v-list-item-title>
          <!-- <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle> -->
          <v-list-item-subtitle >{{user.branch_name}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

       <!-- <v-list-item>
        <v-list-item-content>
           <v-btn rounded color="info" small block dark >Edit Profile</v-btn>
        </v-list-item-content>
      </v-list-item> -->

       <v-divider></v-divider>

       <v-list class="pb-0 pt-0" nav light
        dense >
        <div v-for="item in menu" :key="item.id">
            <v-list-item
	          :to="item.url"
            :color="color_text"

            v-if="item.status_child == false && hakAccess(user.role,item.access) == true"
            >
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title color="white">{{ item.description }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-group

                no-action

                v-else-if="hakAccess(user.role,item.access) == true"
                style="color:#fcac1f !important"
                :color="color_text"
                class="list-group"
                >
                <template v-slot:activator>
                <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="item.description"></v-list-item-title>
                </v-list-item-content>
                </template>
                <template  v-for="subItem in item.child_menu">
                  <v-list-item

                  :key="subItem.id"
                  :to="subItem.url"
                  :color="color_text"
                  class="child-menu"
                  v-if="!subItem.notaccess || notAccess(user.role,subItem.notaccess) == false"
                  >

                  <v-list-item-icon>
                  <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                      <v-list-item-title v-text="subItem.description"></v-list-item-title>
                  </v-list-item-content>
                  </v-list-item>
                </template>

            </v-list-group>

        </div>
      </v-list>
</v-navigation-drawer>
</div>

</template>


<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'sidebar',
  data () {
    return {
      color_text:'#fcac1f',
      menu: [
        {
          icon: 'fal fa-window',
          description: 'Dashboard',
          url: '/dashboard',
          status_child: false,
          access:['administrator','superadmin','owner','manager']
        },

        {
          icon: 'fal fa-shopping-cart',
          description: 'Transaction',
          status_child: true,
          access:['administrator','superadmin','owner','manager','cashier','kasir'],
          child_menu: [
            {
              icon: 'fal fa-shopping-cart',
              description: 'Point Of Sale',
              url: '/pos',
            },
            {
              icon: 'fal fa-server',
              description: 'Inventory In',
              url: '/inventory-in',
            },
            {
              icon: 'fal fa-server',
              description: 'Inventory Out',
              url: '/inventory-out',
            },
             {
              icon: 'fal fa-server',
              description: 'Inventory Move',
              url: '/inventory-move',
            },
            {
              icon: 'fal fa-credit-card',
              description: 'Expense',
              url: '/expense',
            },
            {
              icon: 'fal fa-shopping-cart',
              description: 'Purchase',
              url: '/purchase',
            }
          ]
        },

        {
          url: '/management',
          icon: 'fal fa-cog',
          description: 'Management',
          status_child: true,
          access:['administrator','superadmin','owner','manager'],
          child_menu: [
            {
              icon: 'mdi mdi-store',
              description: 'Branch',
              url:'/branch',
            },
              {
              icon: 'fal fa-users',
              description: 'Employee',
              url: '/employee',
            },
             {
              icon: 'fal fa-users',
              description: 'User Role',
              url: '/users-role',
              notaccess:['manager']
            },
            {
              icon: 'fal fa-user',
              description: 'Job Title',
              url: '/jobtitle',
            },
                {
              icon: 'fal fa-users',
              description: 'Users',
              url: '/users',
              notaccess:['manager']
            },


            {
              icon: 'fal fa-cube',
              description: 'Products',
              url: '/products',
            },
               {
              icon: 'fal fa-copy',
              description: 'Product Category',
              url: '/category',
            },

            {
              icon: 'mdi mdi-grid',
              description: 'Product part',
              url: '/product-part',
            },
            {
              icon: 'fal fa-balance-scale',
              description: 'Uom',
              url: '/uom',
            },



            {
              icon: 'fal fa-tag',
              description: 'Sales Type',
              url: '/sales-type',
            },
            {
              icon: 'fal fa-tag',
              description: 'Sales Price',
              url: '/sales-price',
            },


               {
              icon: 'mdi mdi-wallet',
              description: 'Payment Method',
              url: '/payment',
            },

            {
              icon: 'fal fa-users',
              description: 'Customer',
              url: '/customer',
            },

            {
              icon: 'fal fa-users',
              description: 'Vendor',
              url: '/vendor',
            }

          ]

        },
        {
          icon: 'fal fa-chart-bar',
          description: 'Report',
          status_child: true,
          access:['administrator','superadmin','manager','owner'],
          child_menu: [
            {
              icon: 'fal fa-server',
              description: 'Purchase Order',
              url: '/purchase/report',
            },
            {
              icon: 'fal fa-server',
              description: 'Attendance',
              url: '/attendance/report',
            },
             {
              icon: 'fal fa-server',
              description: 'Expenses',
              url: '/expense/report',
            },
            {
              icon: 'fal fa-server',
              description: 'Inventory In',
              url: '/inventory-in/report',
            },
            {
              icon: 'fal fa-server',
              description: 'Inventory Out',
              url: '/inventory-out/report',
            },
            {
              icon: 'fal fa-server',
              description: 'Inventory Move',
              url: '/inventory-move/report',
            },
            {
              icon: 'fal fa-server',
              description: 'Stock',
              url: '/stock/report',
            },
            {
              icon: 'fal fa-server',
              description: 'Stock Balance',
              url: '/stock/ballance',
            },
            {
              icon: 'fal fa-server',
              description: 'Sales',
              url: '/sales/report',
            },
            {
              icon: 'fal fa-server',
              description: 'Profit/loss',
              url: '/profit/report',
              notaccess:['manager']

            },
            {
              icon: 'fal fa-server',
              description: 'Fixed Asset',
              url: '/profit/fixedasset',
              notaccess:['manager']
            }
          ]
        },
        {
          icon: 'fal fa-cogs',
          description: 'Setting',
          status_child: true,
          access:['administrator','superadmin','manager','cashier','kasir','owner'],
          child_menu: [
            {
              icon: 'fal fa-users',
              description: 'User Management',
              url: '/user-management',
            },
          ]
        }
      ]
    }
  },
  computed: {
    // mapping state sideBar menggunakan map getter
    ...mapGetters({
      sideBar: 'sidebar',
      color: 'layout/color',
      user: 'auth/user'

    }),
    // ubah properti data drawer menjadi computed dimana nilainya membaca dari state sideBar
    drawer: {
      get () {
        return this.sideBar
      },
      set (value) {
        this.setSidebar(value)
      }
    }
  },
  filters: {
    slug: function (value) {
      if (!value) return ''
      return value.toString().toLowerCase()
        .replace(/^-+/, '')
        .replace(/-+$/, '')
        .replace(/\s+/g, '-')
        .replace(/\-\-+/g, '-')
        .replace(/[^\w\-]+/g, '')
    }
  },

  methods: {
    // mapping action setSideBar pada store menggunakan map action
    ...mapActions({
		    setSidebar: 'setSidebar'
    }),

    openDialog () {
      this.setDialog(true)
    },

    hakAccess(role,access) {
      let akses = role.toLowerCase()
      let n = access.includes(akses);
      return n
    },
    notAccess(role,access) {
      let akses = role.toLowerCase()
      let n = access.includes(akses);
      return n
    }

  },

  created () {

  },
  components: {

  }

}
</script>
