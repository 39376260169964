<template>
	<nav>
		<v-app-bar flat app :color="color" class="custom-modal-title" height="55px">
		    <v-app-bar-nav-icon color="#fcac1f"
		    @click="sideBar()"></v-app-bar-nav-icon>
		    <v-toolbar-title style="color:#fcac1f">{{$route.name}}</v-toolbar-title>
		    <v-spacer></v-spacer>

		      <v-btn class="custom-modal-title" @click="logout" text style="color:#fcac1f !important">Logout</v-btn>

		  </v-app-bar>
	</nav>

</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'navbar',
  methods: {
    ...mapActions({
      setSidebar: 'setSidebar',
      setUser:'auth/setUser'
    }),
    sideBar () {
      this.setSidebar(!this.sidebar)
    },
    async logout() {
      await localStorage.removeItem('user')
      await this.setUser(null)
      this.$router.push('/login')
    }

  },
  computed: {
    ...mapGetters({
      sidebar: 'sidebar',
      color: 'layout/color'
    })
  }
}
</script>
