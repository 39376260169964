export default {
  path: '/purchase',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: '',
      name: 'Purchase',
      component: () => import('./index.vue')
    },
    {
      path: '/purchase/report',
      name: 'Purchase report',
      component: () => import('./report.vue')
    }
  ]
}
