export default {
  path: '/menu-access',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: '',
      name: 'Menu Access',
      component: () => import('./index.vue')
    }
  ]
}
