export default {
  namespaced: true,
  state: {
    employees: [],
    categories: [],
    customers:[],
    sales_types:[],
    sales_prices:[],
    uom:[],
    vendors:[],
    purchase:[],
    branchs:[],
    brands:[],
    payments:[],
    report_purchase:[],
    products:[],
    product_parts:[],
    expenses:[],
    expenses_report:[],
    inventory_in:[],
    inventory_out:[],
    inventory_move:[],
    inventory_in_report:[],
    inventory_out_report:[],
    inventory_move_report:[],
    sales:[],
    stock:[],
    profit:[],
    fixedasset:[],
    users_role:[],
    jabatans:[],
    pos:[],
    users:[],
    users_role:[],
    menu_access:[],
  },
  mutations: {
    data: (state, payload) => {
      state[payload.table_name] = payload.data
    }
  },

  actions: {
    setData: ({ commit }, payload) => {
      commit('data', payload)
    }
  },

  getters: {
    employees: state => state.employees,
    categories: state => state.categories,
    customers: state => state.customers,
    sales_types: state => state.sales_types,
    sales_prices: state => state.sales_prices,
    uom: state => state.uom,
    vendors: state => state.vendors,
    purchase: state => state.purchase,
    branchs: state => state.branchs,
    brands: state => state.brands,
    payments: state => state.payments,
    report_purchase: state => state.report_purchase,
    products: state => state.products,
    product_parts: state => state.product_parts,
    expenses: state => state.expenses,
    expenses_report: state => state.expenses_report,
    inventory_in: state => state.inventory_in,
    inventory_out: state => state.inventory_out,
    inventory_move: state => state.inventory_move,
    inventory_in_report: state => state.inventory_in_report,
    inventory_out_report: state => state.inventory_out_report,
    inventory_move_report: state => state.inventory_move_report,
    sales: state => state.sales,
    stock: state => state.stock,
    profit: state => state.profit,
    fixedasset: state => state.fixedasset,
    users_role: state => state.users_role,
    jabatans: state => state.jabatans,
    pos: state => state.pos,
    users: state => state.users,
    users_role: state => state.users_role,
    menu_access: state => state.menu_access,
  }
}
