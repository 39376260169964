export default {
  path: '/pos',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: '',
      name: 'POS',
      component: () => import('./index.vue')
    },
    {
      path: '/pos/report',
      name: 'POS Report',
      component: () => import('./report.vue')
    }
  ]
}
