export default {
  namespaced: true,
  state: {
    token: '',
    user: null
  },
  mutations: {
    token: (state, payload) => {
      state.token = payload
    },
    user: (state, payload) => {
      state.user = payload
    }
  },

  actions: {
    setToken: ({ commit }, payload) => {
      commit('token', payload)
    },
    setUser: ({ commit }, payload) => {
      commit('user', payload)
    }
  },

  getters: {
    token: state => state.token,
    user: state => state.user
  }
}
