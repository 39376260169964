export default {
  namespaced: true,
  state: {
    color: 'rgba(0,0,0,.87)'
  },
  mutations: {
    color: (state, payload) => {
      state.color = payload
    }

  },

  actions: {

    setColor: ({ commit }, payload) => {
      commit('color', payload)
    }
  },

  getters: {
    color: state => state.color
  }
}
