export default {
  path: '/user-management',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: '',
      name: 'user-management',
      component: () => import('./index.vue')

    }
  ]
}
