export default {
  namespaced: true,
  state: {
    cart: [],
    edit_data:null
  },
  mutations: {
    cart: (state, payload) => {
      state.cart = payload
    },
    removeItem: (state,payload) => {
      let index = state.cart.findIndex( x=> x.id === payload.id)
      state.cart.splice(index,1)
    },
    addQuantity: (state,payload) => {
      let new_quantity = payload
      payload.qty++
      let index = state.cart.findIndex( x=> x.id === payload.id)
      state.cart.splice(index,1,new_quantity)
    },
    removeQuantity: (state,payload) => {
      let new_quantity = payload
      payload.qty--
      let index = state.cart.findIndex( x=> x.id === payload.id)
      state.cart.splice(index,1,new_quantity)
    },
    edit_data:(state,payload) => {
      state.edit_data = payload
    }
  },
  actions: {
    setCart: ({ commit }, payload) => {
      commit('cart', payload)
    },
    setQuantity: ({commit,state},payload) => {
      let new_quantity = state.cart.find( x=> x.id === payload.id)
      commit(payload.action,new_quantity)
    },
    setEditData: ({ commit }, payload) => {
      commit('edit_data', payload)
    },
  },
  getters: {
    cart: state => state.cart,
    edit_data: state => state.edit_data
  }
}
