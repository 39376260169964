<template>
    <div>
    <v-app>
        <Header v-if="user" />
        <Sidebar v-if="user" />
        <Snakbar/>
        <v-main class="">
        <v-container class="dk-pos-container" fluid grid-list-md text-xs-center >
            <v-slide-y-transition mode="out-in">
            <router-view></router-view>
            </v-slide-y-transition>
        </v-container>
        </v-main>
    </v-app>
    </div>
</template>

<script>
import Header from './components/layouts/Header'
import HelloWorld from './components/HelloWorld'
import Sidebar from './components/layouts/Sidebar'
import Snakbar from './components/external/Snakbar'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  head: {
       link: [
             {
                 rel: "icon",
                 href: require("./assets/logo.png")
             },
          ]
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  components: {
    HelloWorld,
    Header,
    Sidebar,
    Snakbar
  },

  data: () => ({
    //
  })
}
</script>
<style lang="scss">
  @import 'assets/scss/style';
</style>
