import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });
Vue.filter('tgl_sales',function(value) {
  let time = new Date(value);
  let tgl =
    parseInt(time.getDate()) < 10 ? "0" + time.getDate() : time.getDate();
  let bln =
    time.getMonth() + 1 < 10
      ? "0" + (time.getMonth() + 1)
      : time.getMonth() + 1;
  let jam = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
  let menit =
    time.getUTCMinutes() <= 9
      ? "0" + time.getUTCMinutes().toString()
      : time.getUTCMinutes();
  let date = `${bln}-${tgl}-${time.getFullYear()} ` + jam + ":" + menit;
  return date;
} )
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
