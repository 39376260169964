export default {
  path: '/inventory-in',
  component: () => import('../../../views/Index.vue'),
  meta: { auth: true },
  children: [
    {
      path: '',
      name: 'Inventory In',
      component: () => import('./index.vue')
    },
    {
      path: 'report',
      name: 'Inventory In Report',
      component: () => import('./report.vue')
    }
  ]
}
